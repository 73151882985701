<template>
  <div class="LeaderboardsView">
    <div class="Leaderboards">
      <div class="titleAndTabsContainer">
        <h2 class="leaderboardTitle">Leaderboard</h2>
        <div class="tabs">
          <div
            v-if="myTeams.length > 1"
            class="tab"
            :class="{ selected: tabSelected === 'myTeams' }"
            @click="tabSelected = 'myTeams'"
            >{{ $t('myTeams') }}</div
          >
          <div class="tab" :class="{ selected: tabSelected === 'members' }" @click="tabSelected = 'members'">{{
            $t('members')
          }}</div>
        </div>
      </div>

      <TeamsLeaderboard v-if="tabSelected !== 'members'" :teams-list="myTeams" />

      <MembersLeaderboard v-if="tabSelected === 'members'" :members-list="onlyUniqueMembers" />
    </div>

    <DashboardTopBar ref="topbar" class="topbar" :xtransparent="true" theme="dark-bg" />
  </div>
</template>

<translations>

    allTeams: All teams
    allTeams_no: Alle team
    myTeams: My teams
    myTeams_no: Mine team
    members: Members
    members_no: Medlemmer

    braindates: BRAIN DATES
    braindates_no: HJERNEDATES
    member: MEMBER
    member_no: MEDLEM


</translations>

<script>
import apollo from '@/apollo-client';
import gql from 'graphql-tag';
import DashboardTopBar from '@/modules/dashboard/components/DashboardTopBar';
import TeamsLeaderboard from './TeamsLeaderboard';
import MembersLeaderboard from './MembersLeaderboard';
import uniqBy from 'lodash/uniqBy';
import _ from 'lodash';

export default {
  components: {
    DashboardTopBar,
    TeamsLeaderboard,
    MembersLeaderboard,
  },
  data: function () {
    return {
      teamLeaderboardsList: [],
      teamLeaderboardFullsList: [],
      teamsList: [],
      teamMemberRankingsList: [],
      tabSelected: 'myTeams',
    };
  },
  computed: {
    onlyUniqueMembers() {
      return uniqBy(this.myMembers, 'userId');
    },

    myMembers() {
      const myMembers = this.teamLeaderboardFullsList.filter(item => this.teamsList.find(t => t.id === item.teamId));
      for (var member of this.membersOfPublicTeams) {
        myMembers.push(member);
      }
      const byLifepoints = (a, b) => b.braindates + b.crystals - (a.braindates + a.crystals);
      return myMembers.sort(byLifepoints);
    },

    membersOfPublicTeams() {
      const allMembers = this.teamMemberRankingsList;
      const membersOfPublicTeams = allMembers.filter(
        item => item.teamName === 'UA Grande' || item.teamName === 'MA Scanners'
      );
      return membersOfPublicTeams;
    },

    myTeams() {
      const myMembers = this.teamLeaderboardFullsList.filter(item => this.teamsList.find(t => t.id === item.teamId));
      for (var member of this.membersOfPublicTeams) {
        myMembers.push(member);
      }
      const teams = this.groupTeams(myMembers);
      return teams;
    },
  },
  mounted() {
    this.fetchAllTeamsAndMembers();
  },
  methods: {
    async fetchAllTeamsAndMembers() {
      const result = await apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamLeaderboard {
            __typename
            teamLeaderboardsList {
              braindates
              crystals
              name
              id
            }
            teamsList {
              id
            }
            teamLeaderboardFullsList {
              avatar
              braindates
              crystals
              displayName
              teamId
              teamName
              userId
            }
            teamMemberRankingsList {
              avatar
              braindates
              crystals
              displayName
              teamId
              teamName
              userId
            }
          }
        `,
      });
      const byLifepoints = (a, b) => b.braindates + b.crystals - (a.braindates + a.crystals);
      this.teamLeaderboardsList = result.data.teamLeaderboardsList.sort(byLifepoints);
      this.teamLeaderboardFullsList = result.data.teamLeaderboardFullsList;
      this.teamsList = result.data.teamsList;
      this.teamMemberRankingsList = result.data.teamMemberRankingsList;
    },
    groupTeams(myMembers) {
      const groupedTeams = _.chain(myMembers)
        .groupBy('teamId')
        .map((members, teamId) => {
          return {
            teamId,
            teamName: members[0].teamName,
            lifePoints: members.reduce((accumulator, member) => {
              return accumulator + (member.braindates + member.crystals);
            }, 0),
            memberCount: members.length,
          };
        })
        .value();
      const byLifepoints = (a, b) =>
        Math.floor(b.lifePoints / b.memberCount) - Math.floor(a.lifePoints / a.memberCount);
      return groupedTeams.sort(byLifepoints);
    },
  },
};
</script>

<style lang="scss" scoped>
.LeaderboardsView {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  overflow: auto;
  background: #110b30;

  .Leaderboards {
    display: flex;
    flex-direction: column;
    width: 50em;
    justify-content: center;
    margin-top: 6em;
    margin-bottom: 4em;
    @media (max-width: 620px) {
      width: 95vw;
      margin-top: 5em;
    }
  }
  .titleAndTabsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 620px) {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 4vw;
    }
    .leaderboardTitle {
      font-size: 1.7em;
      font-weight: 600;
      @media (max-width: 620px) {
        margin-bottom: 8vw;
      }
    }
    .tabs {
      display: flex;
      flex-direction: row;
      background-color: #211850;
      border-radius: 1em;
      padding: 0.2em;
      font-size: 0.9em;
      .tab {
        padding: 0.7em 1.5em;
        border-radius: 1em;
        cursor: pointer;

        &.selected {
          background-color: #3687f7;
        }
      }
    }
  }
  .topbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
</style>
