<template>

  <div class="MembersLeaderboard">

    <div class="top3MembersContainer">
      <div v-for="(member, index) in top3MembersList" :key="index" class="top3MemberContainer" :class="'rank' + (index+1)">
        <Avatar class="rankAvatar" :avatar="member.avatar" />
        <div class="rankNum">{{ index+1 }}</div>
        <div class="rankName">{{ member.displayName }}</div>
        <div class="rankBraindates">{{ member.braindates + member.crystals }}</div>
      </div>

    </div>

    <div class="columnName">
      <div class="columnRankUsersContainer">
        <p class="rank">RANK</p>
        <p>{{ $t('member') }}</p>
      </div>
      <p>{{ $t('lifepoints') }}</p>  
    </div>

    <div class="tableContainer">
      <table class="leaderboardTable">
        <tbody>
          <tr
            v-for="(member, rank) of membersList"
            :key="member.userId">
            <div class="tableRowContainer">
              <div class="rankNameContainer">
                <td class="rankNum">{{ rank + 1 }}</td>
                <Avatar class="avatar" :avatar="member.avatar" />
                <td>{{ member.displayName }}</td>
              </div>
              <div class="braindatesContainer">
                <img class="braindateIcon" src="@/assets/images/leaderboards/lifepointsIcon.svg" alt="">
                <td class="braindatesCount">{{ member.braindates + member.crystals }}</td>
              </div>
            </div>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>

<translations>
    lifepoints: 'LIFE POINTS'
    lifepoints_no: 'LIVSPOENG'
    braindates: BRAIN DATES
    braindates_no: HJERNEDATES
    member: MEMBER
    member_no: MEDLEM

</translations>

<script>
import Avatar from '@/components/Avatar';
export default {
    components: {Avatar},
    props: {
        membersList: {
            type: Array,
            required: true
        }
    },
    computed: {
      top3MembersList() {
        return this.membersList.slice(0, 3);
      },
    }
}
</script>

<style lang="scss" scoped>
 .MembersLeaderboard{
    margin-bottom: 4em;
  }
  .leaderboardTable {
    width: 50em;
    @media (max-width: 620px) {
      width: 95vw;
    }
  }
  .columnName {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #a4b1cd88;
    font-weight: 500;
    font-size: 0.8em;
    text-align: left;
    .columnRankUsersContainer {
      display: flex;
      flex-direction: row;
      .rank {
        width: 4.5em;
      }
    }
  }
  .tableRowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid #a4b1cd2a;
    overflow: auto;
    .rankNameContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .avatar {
        height: 1.9em;
        width: 1.9em;
        margin-right: 1.5em;
      }
      .rankNum {
        width: 2.4em;
      }
    }
    .braindatesContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .braindateIcon {
        width: 1.3em;
      }
      .braindatesCount {
        text-align: end;
        margin-left: 0.5em;
      }
    }
  }
 .memberNumOneImage {
   width: 7em;
   height: 7em;
   border-radius: 6em;
   margin-bottom: 1.3em;
   // margin-top: 0.1em;
   border: 0.25em solid #FFCA28;
 }
 .memberNumTwoAndThreeImage {
   width: 5em;
   height: 5em;
   border-radius: 6em;
   margin-bottom: 1.2em;
   margin-top: 0.3em;
   border: 0.25em solid #3687F7;
 }
 .memberNumTwoAndThreeRankNum {
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #3687F7;
   width: 2em;
   height: 2em;
   border-radius: 2em;
   margin-top: 4.6em;
   border: 0.15em solid white;
   font-size: 0.9em;
 }
 .memberOneRankNum {
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #FFCA28;
   color: #312244;
   width: 2em;
   height: 2em;
   border-radius: 2em;
   margin-top: 2.8em;
   border: 0.25em solid #312244;
   font-size: 0.9em;
 }
 .top3MembersContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #21185056;
    padding: 2em;
    margin-bottom: 1em;
    border-radius: 0.4em;
    height: 16em;
    .top3MemberContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: flex-start;
      text-align: center;
      padding-top: 1.4em;
      width: 8em;
      word-wrap: break-word;
      .rankName {
        font-size: 1.1em;
        padding-bottom: 0.3em;
        @media (max-width: 620px) {
          width: 6em;
        }
      }
      .rankBraindates {
        font-size: 1em;
        opacity: 0.7;
      }
      &.rank1 {
        order: 2;
        padding-top: 0.4em;
        width: 13em;
        .rankAvatar {
            width: 7em;
            height: 7em;
            border-radius: 6em;
            margin-bottom: 1.3em;
            border: 0.25em solid #FFCA28;
        }
        .rankNum {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFCA28;
            color: #312244;
            width: 2em;
            height: 2em;
            border-radius: 2em;
            margin-top: 6.6em;
            border: 0.25em solid #312244;
            font-size: 0.9em;
        }
      }
      &.rank2 {
        order: 1;
        .rankAvatar {
            width: 5em;
            height: 5em;
            border-radius: 6em;
            margin-bottom: 1.2em;
            margin-top: 0.3em;
            border: 0.25em solid #3687F7;
        }
        .rankNum {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3687F7;
            width: 2em;
            height: 2em;
            border-radius: 2em;
            margin-top: 4.6em;
            border: 0.15em solid white;
            font-size: 0.9em;
        }
      }
      &.rank3 {
        order: 3;
        .rankAvatar {
          width: 5em;
          height: 5em;
          border-radius: 6em;
          margin-bottom: 1.2em;
          margin-top: 0.3em;
          border: 0.25em solid #3687F7;
        }
        .rankNum {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3687F7;
            width: 2em;
            height: 2em;
            border-radius: 2em;
            margin-top: 4.6em;
            border: 0.15em solid white;
            font-size: 0.9em;
        }
      }
    }
  }
</style>