<template>
  <div class="TeamsLeaderboard">

    <div class="top3TeamsContainer">
      <div v-for="(team, index) in top3TeamsList" :key="index" class="top3TeamContainer" :class="'rank' + (index+1)">
        <img class="rankImage" :src="require('@/assets/images/leaderboards/team' + (index+1) + 'cup.svg')" alt="">
        <div class="rankName">{{ team.teamName }}</div>
        <div class="rankBraindates">{{ getTeamLifepoints(team.lifePoints, team.memberCount) }}</div>
      </div>

    </div>

    <div class="columnName">
      <div class="columnRankUsersContainer">
        <p class="rank">RANK</p>
        <p>TEAM</p>
      </div>
      <p>{{ $t('lifepoints') }}</p>  
    </div>

    <div class="tableContainer">
      <table class="leaderboardTable">
        <tbody>
          <tr
            v-for="(team, rank) of teamsList"
            :key="rank">
            <div class="tableRowContainer">
              <div class="rankNameContainer">
                <td class="rankNum">{{ rank + 1 }}</td>
                <td>{{ team.teamName }}</td>
              </div>
              <div class="braindatesContainer">
                <img class="braindateIcon" src="@/assets/images/leaderboards/lifepointsIcon.svg" alt="">
                <td class="braindatesCount">{{ getTeamLifepoints(team.lifePoints, team.memberCount) }}</td>
              </div>
            </div>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>

<translations>

    lifepoints: LIFE POINTS
    lifepoints_no: LIVSPOENG

</translations>

<script>
export default {
    props: {
        teamsList: {
            type: Array,
            required: true
        }
    },
    computed: {
      top3TeamsList() {
        return this.teamsList.slice(0, 3);
      },
    },
    methods: {
      getTeamLifepoints(lifePoints, memberCount){
        const teamLifepoints = Math.floor(lifePoints / memberCount);
        return teamLifepoints;
      }
    }

}
</script>

<style lang="scss" scoped>
 .TeamsLeaderboard{
    margin-bottom: 4em;
  }
  .leaderboardTable {
    width: 50em;
    @media (max-width: 620px) {
      width: 95vw;
    }
  }
  .columnName {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #a4b1cd88;
    font-weight: 500;
    font-size: 0.8em;
    text-align: left;
    .columnRankUsersContainer {
      display: flex;
      flex-direction: row;
      
      .rank {
        width: 4.5em;
      }
    }
  }
  .tableRowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid #a4b1cd2a;
    overflow: auto;
    
    .rankNameContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .avatar {
        height: 1.9em;
        width: 1.9em;
        margin-right: 1.5em;
      }
      .rankNum {
        width: 2.4em;
      }
      
    }
    .braindatesContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .braindateIcon {
        width: 1.3em;
      }
      .braindatesCount {
        text-align: end;
        margin-left: 0.5em;
      }
    }
    
  }
.top3TeamsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #21185056;
    padding: 2em;
    margin-bottom: 1em;
    border-radius: 0.4em;
    height: 16em;
    .top3TeamContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: flex-start;
      text-align: center;
      padding-top: 1.4em;
      width: 8em;
      overflow-wrap: break-word;
      word-wrap: break-word;
      .rankName {
        font-size: 1.1em;
        padding-bottom: 0.3em;
        
        @media (max-width: 620px) {
          width: 6em;
        }
      }
      .rankBraindates {
        font-size: 1em;
        opacity: 0.7;
      }
      &.rank1 {
        order: 2;
        padding-top: 0.4em;
        width: 13em;
        .rankImage {
          width: 7.8em;
          height: 7.8em;
          border-radius: 6em;
          margin-bottom: 0.7em;
        }
      }
      &.rank2 {
        order: 1;
        .rankImage {
          width: 6em;
          height: 6em;
          border-radius: 6em;
          margin-bottom: 0.6em;
        }
      }
      &.rank3 {
        order: 3;
        .rankImage {
          width: 6em;
          height: 6em;
          border-radius: 6em;
          margin-bottom: 0.6em;
        }
      }
    }
    
  }
</style>